<template>
  <div>
    <Sidebar />
    <div class="ps-2 mt-7 pt-7">
      <Username />
    </div>
    <keep-alive>
      <div style="margin-bottom: 5em">
        <HeaderNavigasi  />
        <div v-if="isLoading">
          <div v-for="i in 10" :key="i">
            <SkeletonCategory />
          </div>
        </div>
        <div
          v-else
          class="py-4 px-4"
          style="background-color: #E0E0E0;"
          id="myDiv"
          ref="infoBox"
        >
          <div v-for="(category, i) in categories" :key="i" class="pb-2">
            <v-card class="mx-auto cardHeight" flatid="info-box">
              <v-card-text :class="show && category.children.length > 3 ? 'pb-0' : 'pb-3'" >
                <div class="d-flex justify-center">
                  <v-btn
                    plain
                    depressed
                    style="text-transform: none; padding: 3px 3px"
                    class=" text-center black--text"
                    @click.prevent="toCategory(category)"
                  >
                    {{ category.category_name }}
                  </v-btn>
                </div>
                <hr class="my-1" v-if="category.children.length > 0" />
                <div
                  v-for="(subParent, i) in category.children.slice(0, 2)"
                  :key="i"
                >
                  <v-card class="d-flex justify-space-between my-2" flat tile>
                    <v-card class="px-0" flat tile style="width: 30%;">
                      <ul>
                        <li
                          v-if="subParent.children.length != 0"
                          @click.prevent="
                            $router.push(`/items/category/${subParent.slug}`)
                          "
                          style="word-break: break-all"
                        >
                          {{ subParent.category_name }}
                        </li>
                        <li
                          v-else
                          @click.prevent="
                            $router.push(`/items/category/c/${subParent.slug}/1`)
                          "
                          style="word-break: break-all"
                        >
                          {{ subParent.category_name }}
                        </li>
                      </ul>
                    </v-card>
                    <v-card class="px-0" flat tile style="width: 30%;">
                      <div v-for="(subChild, j) in subParent.children" :key="j">
                        <ul>
                          <li
                            v-if="subChild.children.length != 0"
                            @click.prevent="
                              $router.push(`/items/category/${subChild.slug}`)
                            "
                            style="word-break: break-all"
                          >
                            {{ subChild.category_name }}
                          </li>
                          <li
                            v-else
                            @click.prevent="
                              $router.push(`/items/category/c/${subChild.slug}/1`)
                            "
                            style="word-break: break-all"
                          >
                            {{ subChild.category_name }}
                          </li>
                        </ul>
                      </div>
                    </v-card>
                    <v-card class="px-0" flat tile style="width: 30%;">
                      <div v-for="(child, i) in subParent.children" :key="i">
                        <div v-for="(c, j) in child.children" :key="j">
                          <ul>
                            <li
                              @click.prevent="
                                $router.push(`/items/category/c/${c.slug}/1`)
                              "
                              style="word-break: break-all"
                            >
                              {{ c.category_name }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </v-card>
                  </v-card>
                  <hr v-if="i != category.children.length - 1" />
                </div>
              </v-card-text>
              <template v-if="category.children.length > 2">
                <v-expand-transition>
                  <div v-show="show">
                    <v-card-text style="padding-top: 0px">
                      <div
                        v-for="(subParent, z) in category.children.slice(2)"
                        :key="z"
                      >
                        <v-card
                          class="d-flex justify-space-between my-2"
                          flat
                          tile
                        >
                          <v-card class="px-0" flat tile style="width: 30%;">
                            <ul>
                              <li
                                v-if="subParent.children.length != 0"
                                @click.prevent="
                                  $router.push(
                                    `/items/category/${subParent.slug}`
                                  )
                                "
                                style="word-break: break-all"
                              >
                                {{ subParent.category_name }}
                              </li>
                              <li
                                v-else
                                @click.prevent="
                                  $router.push(
                                    `/items/category/c/${subParent.slug}/1`
                                  )
                                "
                                style="word-break: break-all"
                              >
                                {{ subParent.category_name }}
                              </li>
                            </ul>
                          </v-card>
                          <v-card class="px-0" flat tile style="width: 30%;">
                            <div
                              v-for="(subChild, i) in subParent.children"
                              :key="i"
                            >
                              <ul>
                                <li
                                  v-if="subChild.children.length != 0"
                                  @click.prevent="
                                    $router.push(
                                      `/items/category/${subChild.slug}`
                                    )
                                  "
                                  style="word-break: break-all"
                                >
                                  {{ subChild.category_name }}
                                </li>
                                <li
                                  v-else
                                  @click.prevent="
                                    $router.push(
                                      `/items/category/c/${subChild.slug}/1`
                                    )
                                  "
                                  style="word-break: break-all"
                                >
                                  {{ subChild.category_name }}
                                </li>
                              </ul>
                            </div>
                          </v-card>
                          <v-card class="px-0" flat tile style="width: 30%;">
                            <div
                              v-for="(child, i) in subParent.children"
                              :key="i"
                            >
                              <div v-for="(c, j) in child.children" :key="j">
                                <ul>
                                  <li
                                    @click.prevent="
                                      $router.push(
                                        `/items/category/c/${c.slug}/1`
                                      )
                                    "
                                    style="word-break: break-all"
                                  >
                                    {{ c.category_name }}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </v-card>
                        </v-card>
                        <hr v-if="z != category.children.length - 1" />
                      </div>
                    </v-card-text>
                  </div>
                </v-expand-transition>
                <v-card-actions class="d-flex justify-center">
                  <v-btn icon @click="show = !show">
                    <v-icon>{{
                      show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </v-btn>
                </v-card-actions>
              </template>
            </v-card>
          </div>
        </div>
        <CategoryFooter />
      </div>
    </keep-alive>
    <Cashier />
  </div>
</template>

<script>
import User from "../components/User.vue";
import CategoryFooter from "../components/CategoryFooter.vue";
import SkeletonCategory from "../components/skeleton/SkeletonCategory.vue";
import Sidebar from "../components/Sidebar.vue";
import Cashier from "../components/Cashier.vue";
import HeaderNavigasi from "../components/HeaderNavigasi.vue";
export default {
  name: "CategoryTop",
  data() {
    return {
      show: false,
      idStore: "",
      isLoading: false,
      show: false,
    };
  },
  components: {
    Sidebar,
    Cashier,
    HeaderNavigasi,
    CategoryFooter,
    SkeletonCategory,
    Username: User,
  },
  created() {
    this.dispatchFetchAllCategory();
    // this.formatSubChild();
    this.height();
  },
  mounted() {
    // this.formatSubChild();
  },
  methods: {
    toCategory(category) {
      if (category.is_child > 0) {
        this.$router.push(`/items/category/${category.slug}`);
      } else {
        this.$router.push(`/items/category/c/${category.slug}/1`);
      }
    },
    async dispatchFetchAllCategory() {
      this.isLoading = true;
      let res = await this.$store.dispatch("category_module/getCategories");
      this.isLoading = false;
      return res;
    },
    height() {
      // let data = document.getElementById("infoBox");
      const card = document.getElementsByClassName("cardHeight");
      return card;
    },
  },
  computed: {
    categories() {
      return this.$store.state.category_module.allCategoryResult;
    },
  },
};
</script>

<style>
li {
  list-style: square;
  color: black;
}
</style>
